<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Devices">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: false, // disable the check box
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status ? 'Enabled' : 'Disabled' }}
              </b-badge>
            </span>

            <!-- Column: DateFrom -->
            <span v-else-if="props.column.field === 'createdAt'">
              {{ timeFromMillis(props.row.createdAt) }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :disabled="canUpdate" @click="editDevice(props.row.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="canDelete"
                    v-b-modal.modal-danger
                    @click="selectDevice(props.row.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
    <b-modal
      id="modal-danger"
      ok-only
      ok-variant="danger"
      ok-title="Delete"
      modal-class="modal-danger"
      centered
      title="Delete Device"
      @ok="deleteDevice(selectedDevice)"
    >
      <b-card-text>
        Are you sure you want to delete this device?
      </b-card-text>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow, BModal, BCardText, VBModal, BCol, BCard, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@store'
import Ripple from 'vue-ripple-directive'
import axios from 'axios';
import * as api from '@api';

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BRow,
    BModal,
    BCardText,
    BCol,
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Business',
          field: 'business',
        },
        {
          label: 'DataCount',
          field: 'dataCount',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      selectedDevice: null,
      searchTerm: '',
      enabled: [{
        1: 'true',
        2: 'false',
      },
      {
        1: 'light-primary',
        4: 'light-danger',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        true : 'light-primary',
        false: 'light-danger',

        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    timeFromMillis() {
      return date => date; // date.toDate().toDateString();
    },
    canUpdate() {
      return !store.state.auth.me.permissions.devices.update;
    },
    canDelete() {
      return !store.state.auth.me.permissions.devices.delete;
    },
  },
  async created() {
    await store.dispatch('getNodes');
    this.createTable(store.state.nodes.nodes);
  },
  methods: {
    editDevice(val) {
      this.$router.push(`./device/${val}`);
    },
    selectDevice(val) {
      this.selectedDevice = val;
    },
    async deleteDevice(val) {
      const result = await axios({
        method: 'delete',
        url: `${api.node}?id=${val}`,
      });
      if (result.status === 200) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Device Deleted',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Delete Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      await store.dispatch('getNodes');
      this.rows = [];
      this.selectDevice = null;
      this.createTable(store.state.nodes.nodes);
    },
    createTable(nodes) {
      nodes.forEach(n => {
        const biz = store.state.businesses.businesses.find(b => b.id === n.business);
        this.rows.push({
          name: n.name,
          id: n.id,
          status: n.status,
          business: biz.name,
          dataCount: n.dataCount,
        });
      });
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
